<template>
  <div class="panel-content-right h-stretch v-stretch hide-y">
    <div ref="dialog" class="overlay">
      <div class="card">
        <div class="content">
          <div class="v-stack gap-5">
            <a class="heading-title-2">Remove</a>
            <div>
              Do you wish to remove
              {{ selectedUser ? selectedUser.fullName : "No User Error" }} from
              this conversation?
            </div>
            <div class="h-stack h-space-between">
              <button class="submit" @click="dismissRemoveDialog()">
                Cancel
              </button>
              <button class="edit" @click="removeUser()">Remove</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="dialogConversation" class="overlay">
      <div class="card">
        <div class="content">
          <div class="v-stack gap-5">
            <a class="heading-title-2">Remove</a>
            <div>Do you wish to remove yourself from this conversation?</div>
            <div class="h-stack h-space-between">
              <button class="submit" @click="dismissRemoveConversationDialog()">
                Cancel
              </button>
              <button class="edit" @click="removeConversation()">Remove</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel-left v-stretch h-stretch">
      <div class="pane-top gap-3 hide-y">
        <div class="h-stack h-space-between">
          <a class="heading-title-2">Conversations</a>
          <div class="h-stack gap-3">
            <button
              @click="showRemoveConversationDialog()"
              class="edit"
              v-if="selectedConversation"
            >
              x
            </button>
            <button @click="newConversation()" class="add">+</button>
          </div>
        </div>
        <div class="v-stack h-stretch v-start gap-3 scroll">
          <div
            class="chat-row pane-left gap-5"
            v-bind:class="[
              selectedConversation == conversation ? 'selected' : '',
            ]"
            v-for="conversation in sortedConversations"
            :key="conversation._id"
            @click="selectConversation(conversation, false)"
          >
            <div class="chat-icon">
              <div
                class="chat-icon-initials"
                v-if="conversation.users.length <= 2"
              >
                {{ conversation.info.initials }}
              </div>
              <i
                v-if="conversation.users.length > 2"
                class="fas fa-users fa-2x"
              ></i>
            </div>
            <div class="v-stack h-start v-center gap-2">
              <div
                class="chat-row-title"
                @click="startEditingConversation(conversation)"
                v-if="conversation.edit != true"
              >
                {{ conversation.name || conversation.info.name || "Empty" }}
              </div>
              <input
                class="conversation-name"
                type="text"
                v-model="conversation.name"
                v-if="conversation.edit"
                placeholder="name your conversation"
                @keydown.enter="updateConversation(conversation)"
                v-on:focusout="updateConversation(conversation)"
                v-focus
              />
              <div class="chat-row-content">
                {{ getConversationLastMessage(conversation) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pane-top gap-3 hide-y" v-if="selectedConversation">
      <div class="chat-panel h-stack h-space-between">
        <div class="h-stack h-start gap-3 recipients">
          <div
            class="chat-icon"
            v-for="user in selectedConversation.users.filter(
              (user) => user != $store.state.id
            )"
            :key="user"
            @click="showRemoveDialog(user)"
          >
            <div class="chat-icon-initials">
              {{ getUserInfoObject(user).initials }}
            </div>
            <div class="tooltip">{{ getUserInfoObject(user).name }}</div>
            <div class="remove">X</div>
          </div>
        </div>
        <div class="people-search-container">
          <input
            id="searchInput"
            class="people-search"
            v-model="search"
            placeholder="Search for recipients..."
            v-on:focusin="searchActive = true"
            v-on:focusout="looseFocus()"
          />
          <div
            class="v-stack people-search-list h-stretch"
            v-show="searchActive"
          >
            <div class="people-search-item" @click="addAllUsers()">All</div>
            <div
              class="people-search-item"
              v-for="user in filteredUsers"
              :key="user._id"
              @click="addUser(user)"
            >
              {{ user.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="pane-bottom gap-3 hide-y scroll-area">
        <button
          class="scroll-button submit"
          ref="scrollButton"
          @click="scrollToBottom()"
        >
          Scroll down
        </button>
        <div
          class="v-stack h-stretch v-stretch gap-3 scroll-y"
          ref="scroll"
          @scroll="handleScroll()"
        >
          <div class="v-stack h-stretch v-end gap-3">
            <div
              class="v-stack h-stretch gap-3"
              v-for="message in selectedConversation.messages"
              :key="message._id"
            >
              <template v-if="message.user != $store.state.id">
                <label class="chat-name"
                  >{{ getUserInfoObject(message.user).name }}
                  <div class="chat-time">
                    {{ formatDate(message.timestamp, "DD.MM.YYYY HH:mm") }}
                  </div></label
                >
                <div class="v-stack h-start">
                  <div class="pane-left gap-3">
                    <div class="chat-icon">
                      <div class="chat-icon-initials">
                        {{ getUserInfoObject(message.user).initials }}
                      </div>
                    </div>
                    <div class="chat-message">{{ message.text }}</div>
                  </div>
                </div>
              </template>
              <template v-if="message.user == $store.state.id">
                <label class="chat-name my"
                  >{{ getUserInfoObject(message.user).name }}
                  <div class="chat-time">
                    {{ formatDate(message.timestamp, "DD.MM.YYYY HH:mm") }}
                  </div></label
                >
                <div class="v-stack h-end">
                  <div class="pane-right gap-3">
                    <div class="chat-message my">
                      {{ message.text }}
                    </div>
                    <div class="chat-icon">
                      <div class="chat-icon-initials">
                        {{ getUserInfoObject(message.user).initials }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="h-stack h-stretch">
          <div class="pane-right gap-3">
            <input
              type="text"
              v-model="message"
              placeholder="Type in your message..."
              @keydown.enter="sendMessage()"
            />
            <button class="submit" @click="sendMessage()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Fuse from "fuse.js";
import moment from "moment";
import EventBus from "@/eventbus.js";
import Vue from "vue";

export default {
  beforeRouteEnter(to, from, next) {
    document.getElementById("page-footer").style.display = "none";
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.getElementById("page-footer").style.display = "grid";
    next();
  },
  data() {
    return {
      search: "",
      message: "",
      selectedConversation: null,
      selectedUser: null,
      users: [],
      conversations: [],
      searchActive: false,
      doubleClickStarted: false,
    };
  },
  methods: {
    ...mapActions([
      "getConversations",
      "addConversation",
      "editConversation",
      "addConversationUser",
      "removeConversationUser",
      "sendConversationMessage",
      "getUsers",
    ]),
    formatDate(dateString, format) {
      if (dateString) {
        const date = moment(dateString);
        return date.format(format);
      } else {
        return null;
      }
    },
    updateConversation(conversation) {
      conversation.edit = false;
      this.editConversation({
        id: conversation._id,
        name: conversation.name,
      })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startEditingConversation(conversation) {
      if (this.doubleClickStarted) {
        conversation.edit = true;
        this.$forceUpdate();
      } else {
        this.doubleClickStarted = true;
        setTimeout(() => {
          this.doubleClickStarted = false;
        }, 300);
      }
    },
    looseFocus() {
      const element = document.elementFromPoint(Vue.mouseX, Vue.mouseY);
      if (!element.classList.contains("people-search-item")) {
        this.searchActive = false;
      }
    },
    showRemoveDialog(userId) {
      this.selectedUser = this.getUserById(userId);
      this.$refs.dialog.classList.add("show");
    },
    showRemoveConversationDialog() {
      this.$refs.dialogConversation.classList.add("show");
    },
    dismissRemoveDialog() {
      this.$refs.dialog.classList.remove("show");
    },
    dismissRemoveConversationDialog() {
      this.$refs.dialogConversation.classList.remove("show");
    },
    removeUser() {
      this.dismissRemoveDialog();
      if (this.selectedConversation && this.selectedUser) {
        this.removeConversationUser({
          conversation: this.selectedConversation._id,
          user: this.selectedUser._id,
        })
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    removeConversation() {
      this.dismissRemoveConversationDialog();
      if (this.selectedConversation) {
        this.removeConversationUser({
          conversation: this.selectedConversation._id,
          user: this.$store.state.id,
        })
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    newConversation() {
      this.addConversation()
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addAllUsers() {
      const users = this.users.filter((user) => {
        if (user._id == this.$store.state.id) {
          return false;
        }
        if (this.selectedConversation.users.includes(user._id)) {
          return false;
        }
        return true;
      });

      for (const user of users) {
        this.addUser(user);
      }

      if (users.length == 0) {
        this.search = "";
        this.searchActive = false;
      }
    },
    addUser(user) {
      if (this.selectedConversation) {
        this.addConversationUser({
          user: user._id,
          conversation: this.selectedConversation._id,
        })
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }

      this.search = "";
      this.searchActive = false;
    },
    sendMessage() {
      const text = this.message.trim();
      if (text.length > 0 && this.selectedConversation) {
        this.sendConversationMessage({
          conversation: this.selectedConversation._id,
          text: this.message,
        })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
      this.message = "";
    },
    selectConversation(conversation, smooth = true) {
      this.selectedConversation = conversation;
      this.scrollToBottom(smooth);
    },
    getUserById(id) {
      for (const user of this.users) {
        if (user._id == id) {
          return user;
        }
      }
      return null;
    },
    getUserInfoObject(id) {
      const user = this.getUserById(id);
      if (user) {
        return {
          name: user.fullName,
          initials: user.firstName[0] + user.lastName[0],
        };
      }
      return {
        name: "",
        initials: "",
      };
    },
    getConversationUserInfo(conversation) {
      for (const user of conversation.users) {
        if (user != this.$store.state.id) {
          return this.getUserInfoObject(user);
        }
      }
      return this.getUserInfoObject("");
    },
    getConversationLastMessage(conversation) {
      if (conversation.messages.length > 0) {
        return conversation.messages[conversation.messages.length - 1].text;
      }
      return "No Messages";
    },
    scrollToBottom(smooth = true) {
      this.$nextTick(() => {
        this.$refs.scrollButton.style.display = "none";

        if (smooth) {
          this.$refs.scroll.scroll({
            top: this.$refs.scroll.scrollHeight,
            behavior: "smooth",
          });
        } else {
          this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
        }
      });
    },
    handleScroll() {
      if (
        this.$refs.scroll.scrollTop ==
        this.$refs.scroll.scrollHeight - this.$refs.scroll.clientHeight
      ) {
        this.$refs.scrollButton.style.display = "none";
      } else {
        this.$refs.scrollButton.style.display = "block";
      }
    },
    refresh() {
      this.getConversations()
        .then((conversations) => {
          this.conversations = conversations;
          for (const conversation of this.conversations) {
            conversation.info = this.getConversationUserInfo(conversation);
          }

          if (this.selectedConversation) {
            for (const conversation of this.conversations) {
              if (conversation._id == this.selectedConversation._id) {
                this.selectConversation(conversation);
                return;
              }
            }
          }

          if (this.conversations.length > 0) {
            this.selectConversation(this.conversations[0], false);
          } else {
            this.selectedConversation = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    sortedConversations() {
      let sorted = this.conversations.slice();

      sorted.sort((a, b) => {
        if (a.messages.length > 0 && b.messages.length > 0) {
          const timeA = a.messages[a.messages.length - 1].timestamp;
          const timeB = b.messages[b.messages.length - 1].timestamp;
          return moment(timeB).valueOf() - moment(timeA).valueOf();
        } else {
          if (a.users.length == 1) {
            return -1;
          } else {
            if (a.messages.length == 0) {
              return 0;
            }
          }
          return 1;
        }
      });

      return sorted;
    },
    filteredUsers() {
      let users = this.users;

      const query = this.search.trim();
      if (query.length > 0) {
        const options = {
          keys: ["fullName"],
          threshold: 0.3,
          ignoreLocation: true,
        };
        const fuse = new Fuse(users, options);
        users = fuse.search(query).map((resultItem) => resultItem.item);
      }

      return users
        .filter((user) => {
          if (user.id == this.$store.state.id) {
            return false;
          }
          if (this.selectedConversation.users.includes(user._id)) {
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          return a.fullName
            .toLowerCase()
            .localeCompare(b.fullName.toLowerCase(), "cs");
        });
    },
  },
  mounted() {
    EventBus.$on("newChatMessage", () => {
      this.refresh();
    });

    this.getUsers()
      .then((users) => {
        this.users = users.filter((user) => user.accessLevel > 4);
        this.refresh();
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
}

.conversation-name {
  padding: 6px;
}

.scroll-button {
  position: absolute;
  display: none;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 7;
}

.chat-name {
  text-align: left;
  position: relative;
}

.chat-name.my {
  text-align: right;
}

.chat-time {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.panel-left {
  border-right: solid 1px lightgrey;
  padding-right: 20px;
}

.panel-content-right {
  height: calc(100vh - 150px);
}

a {
  padding: 0px;
  align-self: center;
}

.chat-icon {
  height: 60px;
  width: 60px;
  align-self: center;
  border-radius: 50%;
  border: 1px solid grey;
  position: relative;
  background-color: white;
}

.chat-icon i {
  position: absolute;
  // bottom: 0px;
  // right: 0px;
  border-radius: 50%;
  padding: 3px;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chat-message {
  background-color: lightskyblue;
  border-radius: 16px;
  padding: 12px;
  height: fit-content;
  color: black;
  max-width: 400px;
  text-align: left;
}

.chat-message.my {
  background-color: #6065dc;
  color: white;
}

.chat-icon-initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  user-select: none;
}

.chat-row {
  padding-left: 12px;
  padding-right: 12px;
  height: 80px;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;
}

.chat-row.selected {
  background-color: lightskyblue;
}

.chat-row:hover:not(.selected) {
  background-color: lightgray;
}

.chat-row-title {
  text-align: start;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat-row-content {
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.chat-panel {
  border-bottom: 1px solid lightgray;
  padding-bottom: 12px;
}

.people-search-container {
  position: relative;
  align-self: center;
}

.people-search-list {
  top: 50px;
  position: absolute;
  width: 100%;
}

.people-search-item:hover {
  background-color: lightskyblue;
  cursor: pointer;
  text-decoration: underline;
}

.people-search-item {
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding: 6px;
  text-align: left;
  background-color: white;
  z-index: 10;
}

.people-search-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-top: 1px solid gray;
}

.people-search-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.people-search {
  width: 260px;
  height: 42px;
}

.scroll {
  overflow-y: auto;
}

.recipients .chat-icon {
  width: 40px;
  height: 40px;
}

.recipients .chat-icon:hover {
  background-color: lightgray;
}

.recipients .chat-icon:hover {
  .chat-icon-initials {
    display: none;
  }

  .remove {
    display: block;
  }

  .tooltip {
    opacity: 1;
  }
}

.chat-icon .tooltip {
  position: absolute;
  top: 120%;
  left: 10%;
  transform: translate(0%, 0%);
  background-color: grey;
  color: white;
  border-radius: 6px;
  padding: 6px;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
  z-index: 20;
}

.chat-icon .tooltip::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 23%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.chat-icon .remove {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  user-select: none;
  font-size: 32px;
  color: grey;
}

button:disabled {
  cursor: unset;
}
</style>